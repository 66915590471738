import { PipelineOption } from 'researchProjectManagement/ResearchProjectAutocompleteOptions';
import useAuth from '../../auth/UseAuth';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { GetPipelines } from '../../data/SuperDuperFiestaData';

export const useSuperDuperFiestaPipelineOptions = (): [
  PipelineOption[],
  boolean,
  Dispatch<SetStateAction<boolean>>
] => {
  const { accessToken } = useAuth();
  const [pipelineOptions, setPipelineOptions] = useState<PipelineOption[]>([]);
  const [refresh, refreshOptions] = useState<boolean>(true);

  useEffect(() => {
    async function getData() {
      const pipelines = [...((await GetPipelines(accessToken)) ?? [])];
      pipelines.sort((a, b) => a.name.localeCompare(b.name));
      setPipelineOptions(
        pipelines.map(p => {
          return { label: p.name, pipeline: p };
        })
      );
    }

    getData();
  }, [refresh, accessToken]);

  return [pipelineOptions, refresh, refreshOptions];
};
