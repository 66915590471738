import React, { ChangeEvent, useEffect, useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingStatus } from 'components/LoadingStatus';
import { CreateCustomerModal } from './CreateCustomerModal';
import { LlCustomer } from 'data/LlCustomerData';
import { ResearchProjectDetails } from 'data/ResearchProjectData';
import {
  DiseaseAreaOption,
  LlCustomerOption,
  PipelineOption,
  ResearchProjectStatusOption,
} from './ResearchProjectAutocompleteOptions';
import { appSettings } from 'AppSettings';
import useAuth from 'auth/UseAuth';
import { DialogCloseButton } from '../components/DialogCloseButton';
import { useResearchProjectStatusOptions } from 'components/hooks/UseResearchProjectStatusOptions';
import { CancelButton } from 'components/CancelButton';
import { DeleteButton } from 'components/DeleteButton';
import { PrimaryButton } from 'components/PrimaryButton';
import { t } from 'i18next';

export interface UpdateResearchProjectModalProps {
  open: boolean;
  researchProject: ResearchProjectDetails;
  customerOptions: LlCustomerOption[];
  diseaseAreaOptions: DiseaseAreaOption[];
  pipelineOptions: ReadonlyArray<PipelineOption>;
  onResearchProjectUpdate: Function;
  onCustomerCreate: Function;
  onClose: Function;
}

export const UpdateResearchProjectModal = ({
  open,
  researchProject,
  customerOptions,
  diseaseAreaOptions,
  pipelineOptions,
  onResearchProjectUpdate,
  onCustomerCreate,
  onClose,
}: UpdateResearchProjectModalProps) => {
  const { accessToken } = useAuth();

  const [researchProjectStatusOptions] = useResearchProjectStatusOptions();

  const defaultResearchProjectStatus: ResearchProjectStatusOption = {
    label: researchProject.status,
    status: researchProject.status,
  };

  const [modalOpen, setModalOpen] = useState<boolean>(open);
  const [status, setStatus] = useState<LoadingStatus>('NotStarted');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedCustomer, setSelectedCustomer] = useState<LlCustomerOption | undefined>();
  useEffect(() => {
    if (researchProject === undefined || researchProject.customer.name === undefined) {
      setSelectedCustomer(undefined);
      return;
    }
    const customerId = researchProject.customer.llCustomerId;
    const customer = customerOptions.find(c => c.customer.llCustomerId === customerId);
    setSelectedCustomer(customer);
  }, [researchProject, customerOptions]);
  const [selectedProjectName, setSelectedProjectName] = useState<string>(researchProject.name);
  const [selectedExpectedCohortSize, setSelectedExpectedCohortSize] = useState<number | undefined>(
    researchProject.expectedCohortSize
  );
  const [selectedDiseaseArea, setSelectedDiseaseArea] = useState<DiseaseAreaOption | undefined>();
  useEffect(() => {
    if (researchProject === undefined || (researchProject.diseaseAreas ?? []).length === 0) {
      setSelectedDiseaseArea(undefined);
      return;
    }
    const diseaseAreaId = researchProject.diseaseAreas[0].diseaseAreaId;
    const diseaseArea = diseaseAreaOptions.find(d => d.diseaseArea.diseaseAreaId === diseaseAreaId);
    setSelectedDiseaseArea(diseaseArea);
  }, [researchProject, diseaseAreaOptions]);
  const [selectedPipeline, setSelectedPipeline] = useState<PipelineOption | undefined>();
  useEffect(() => {
    if (researchProject?.pipelineId === undefined) {
      setSelectedPipeline(undefined);
      return;
    }
    const pipelineId = researchProject.pipelineId;
    const pipeline = pipelineOptions.find(p => p.pipeline.pipelineId === pipelineId);
    setSelectedPipeline(pipeline);
  }, [researchProject, pipelineOptions]);
  const [selectedResearchProjectStatus, setSelectedResearchProjectStatus] =
    useState<ResearchProjectStatusOption>(defaultResearchProjectStatus);
  const [selectedSalesforceLink, setSelectedSalesforceLink] = useState<string | undefined>(
    researchProject.salesforceLink
  );
  const [selectedMondayLink, setSelectedMondayLink] = useState<string | undefined>(researchProject.mondayLink);

  const handleClose = () => {
    clearSelectedState();
    setModalOpen(false);
    onClose();
  };

  const handleSave = async () => {
    setStatus('Loading');

    if (selectedProjectName === undefined) {
      setErrorMessage('Please enter a project name.');
      setStatus('Error');
    } else if (selectedResearchProjectStatus === undefined) {
      setErrorMessage('Please select a project status.');
      setStatus('Error');
    } else if (selectedCustomer === undefined) {
      setErrorMessage('Please select a customer status.');
      setStatus('Error');
    } else {
      let updateResearchProject: ResearchProjectDetails = {
        researchProjectId: researchProject.researchProjectId,
        name: selectedProjectName,
        customer: selectedCustomer.customer,
        expectedCohortSize: selectedExpectedCohortSize,
        diseaseAreas: selectedDiseaseArea !== undefined ? [selectedDiseaseArea.diseaseArea] : [],
        status: selectedResearchProjectStatus.status,
        salesforceLink: selectedSalesforceLink,
        mondayLink: selectedMondayLink,
        pipelineId: selectedPipeline !== undefined ? selectedPipeline.pipeline.pipelineId : undefined,
      };

      try {
        if (accessToken) {
          const response = await fetch(`${appSettings.api.endpoint}/api/v2/ResearchProjects`, {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateResearchProject),
          });

          if (response.ok) {
            setStatus('Complete');
            handleClose();
            onResearchProjectUpdate();
          } else {
            let responseBody: { message: string } = await response.json();
            setStatus('Error');

            if (responseBody.message) {
              setErrorMessage(responseBody.message);
            } else if (response.status === 401) {
              setErrorMessage('Error: unauthorized user.');
            } else {
              setErrorMessage('Unknown error.');
            }
          }
        }
      } catch (err) {
        setErrorMessage('Unknown error.');
        setStatus('Error');
      }
    }
  };

  const handleDelete = async () => {
    try {
      if (accessToken) {
        const response = await fetch(
          `${appSettings.api.endpoint}/api/v2/ResearchProjects/${researchProject.researchProjectId}`,
          {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }
        );
        if (response.ok) {
          setStatus('Complete');
          handleClose();
          onResearchProjectUpdate();
        } else {
          let message = await response.text();
          setStatus('Error');
          setErrorMessage(message.length > 0 ? message : 'Unknown error.');
        }
      }
    } catch (err) {
      setStatus('Error');
      setErrorMessage('Unknown error.');
    }
  };

  const clearSelectedState = () => {
    setStatus('NotStarted');
    setErrorMessage(undefined);
    setSelectedCustomer(undefined);
    setSelectedProjectName(researchProject.name);
    setSelectedExpectedCohortSize(researchProject.expectedCohortSize);
    setSelectedResearchProjectStatus(defaultResearchProjectStatus);
    setSelectedSalesforceLink(researchProject.salesforceLink);
    setSelectedMondayLink(researchProject.mondayLink);
  };

  const handleProjectNameChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedProjectName(ev.target.value);
  };

  const handleLlCustomerChange = (llCustomer?: LlCustomerOption) => {
    setSelectedCustomer(llCustomer);
  };

  async function handleCreateLlCustomer(newCustomer: LlCustomer) {
    if (accessToken) {
      let newCustomerOption: LlCustomerOption = { label: newCustomer.name, customer: newCustomer };
      setSelectedCustomer(newCustomerOption);
      onCustomerCreate();
    }
  }

  const handleExpectedCohortSizeChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedExpectedCohortSize(ev.target.value ? Number(ev.target.value) : undefined);
  };

  const handleDiseaseAreaChange = (diseaseArea?: DiseaseAreaOption) => {
    setSelectedDiseaseArea(diseaseArea);
  };

  const handlePipelineChange = (pipeline?: PipelineOption) => {
    setSelectedPipeline(pipeline);
  };

  const handleResearchProjectStatusChange = (researchProjectStatusOption: ResearchProjectStatusOption | null) => {
    setSelectedResearchProjectStatus(researchProjectStatusOption ?? defaultResearchProjectStatus);
  };

  const handleSalesForceLinkChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedSalesforceLink(ev.target.value);
  };

  const handleMondayLinkChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setSelectedMondayLink(ev.target.value);
  };

  return (
    <>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          <Typography variant='h4'>Update Research Project</Typography>
        </DialogTitle>
        <DialogContent>
          <Box component='form' sx={{ marginTop: 2 }}>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Autocomplete
                id='update-research-project-customer-input'
                fullWidth
                value={selectedCustomer}
                options={customerOptions}
                renderInput={params => <TextField {...params} label='Customer *' margin='normal' />}
                onChange={(event, value) => handleLlCustomerChange(value ?? undefined)}
              />
              <CreateCustomerModal onCustomerCreate={handleCreateLlCustomer} />
            </Box>
            <TextField
              id='update-research-project-project-name-input'
              fullWidth
              margin='normal'
              label='Project Name *'
              onChange={handleProjectNameChange}
              type='text'
              value={selectedProjectName}
            />
            <TextField
              id='update-research-project-cohort-size-input'
              fullWidth
              margin='normal'
              label='Expected Cohort Size'
              onChange={handleExpectedCohortSizeChange}
              type='number'
              value={selectedExpectedCohortSize}
            />
            <Autocomplete
              id='update-research-project-disease-area-input'
              fullWidth
              value={selectedDiseaseArea}
              options={diseaseAreaOptions}
              renderInput={params => <TextField {...params} label={t('diseaseArea')} margin='normal' />}
              onChange={(event, value) => handleDiseaseAreaChange(value ?? undefined)}
            />
            <Autocomplete
              id='update-research-project-pipeline-input'
              fullWidth
              value={selectedPipeline}
              options={pipelineOptions}
              renderInput={params => <TextField {...params} label={t('pipeline')} margin='normal' />}
              onChange={(event, value) => handlePipelineChange(value ?? undefined)}
            />
            <Autocomplete
              id='update-research-project-status-input'
              fullWidth
              options={researchProjectStatusOptions}
              renderInput={params => (
                <TextField
                  {...params}
                  value={selectedResearchProjectStatus}
                  label='Execution Status *'
                  margin='normal'
                />
              )}
              onChange={(event, value) => handleResearchProjectStatusChange(value)}
              value={selectedResearchProjectStatus}
            />
            <TextField
              id='update-research-project-salesforce-input'
              fullWidth
              margin='normal'
              label='Salesforce Link'
              onChange={handleSalesForceLinkChange}
              type='text'
              value={selectedSalesforceLink}
            />
            <TextField
              id='update-research-project-project-management-input'
              fullWidth
              margin='normal'
              label='Project Managment Link'
              onChange={handleMondayLinkChange}
              type='text'
              value={selectedMondayLink}
            />
          </Box>
          {status === 'Error' && (
            <DialogContentText display='flex' alignItems='center' mt={1} color='error'>
              <ErrorOutlineIcon sx={{ mr: 1 }} />
              {errorMessage}
            </DialogContentText>
          )}
        </DialogContent>
        {status === 'Loading' && <LinearProgress />}
        <DialogActions>
          <CancelButton onClick={handleClose} disabled={status === 'Loading'} />
          <DeleteButton onClick={handleDelete} disabled={status === 'Loading'} />
          <PrimaryButton onClick={handleSave} disabled={status === 'Loading'}>
            Save
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
