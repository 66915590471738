export interface LoadableData<T> {
  loading: boolean;
  error: string | undefined;
  data: T | undefined;
}

export const initialLoadableData = <T>(): LoadableData<T> => ({
  loading: false,
  error: undefined,
  data: undefined,
});

export const loadingData = <T>(): LoadableData<T> => ({
  loading: true,
  error: undefined,
  data: undefined,
});

export const loadedData = <T>(data: T | undefined): LoadableData<T> => ({
  loading: false,
  error: undefined,
  data,
});

export const errorData = <T>(error: string): LoadableData<T> => ({
  loading: false,
  error,
  data: undefined,
});

export const trackLoading = <T>(
  load: () => Promise<T | undefined>,
  callback: (currentState: LoadableData<T>) => void
): Promise<void> => {
  callback(loadingData());
  return load()
    .then(data => callback(loadedData(data)))
    .catch(error => {
      callback(errorData(error.message));
      throw error;
    });
};
