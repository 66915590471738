import { Stack } from '@mui/material';

import React, { useEffect, useState } from 'react';
import LeftNavigation from '../components/leftNav/LeftNavigation';
import PatientFrame from '../components/frame/PatientFrame';
import { useNavigationTriggerRef } from '../components/grids/gridHooks';
import {
  useMeasurement,
  useMeasurementDefinitions,
  usePatient,
  useResearchProjectPatients,
  useSelectMeasurement,
  useSelectResearchProject,
  useStoreAccessToken,
} from '../stores/dataStore';
import useAuth from 'auth/UseAuth';
import assertExhaustive from '../utils/assertExhaustive';

export enum ExpansionState {
  OnlyPatientList,
  OnlyPatientFrame,
  Both,
}

const PatientApprover: React.FC<{ researchProjectId: string }> = ({ researchProjectId }) => {
  const { accessToken } = useAuth();
  const storeAccessToken = useStoreAccessToken();
  useEffect(() => {
    if (accessToken) {
      storeAccessToken(accessToken);
    }
  }, [accessToken, storeAccessToken]);

  const selectResearchProject = useSelectResearchProject();
  useEffect(() => {
    selectResearchProject(researchProjectId);
  }, [researchProjectId, selectResearchProject]);

  // When a review has been completed we automatically move to the next patient.
  const selectNextPatientRef = useNavigationTriggerRef();
  const selectPrevPatientRef = useNavigationTriggerRef(); // Not currently used but wired up for future use.

  const onDoneReviewing = () => {
    selectNextPatientRef.current();
  };

  // Automatically select the first patient in the table when the data has loaded.
  const researchProjectPatientsData = useResearchProjectPatients();
  const patient = usePatient();
  useEffect(() => {
    if (!researchProjectPatientsData.loading && researchProjectPatientsData.data && !patient) {
      selectNextPatientRef.current();
    }
  }, [researchProjectPatientsData, patient, selectNextPatientRef]);

  // Automatically select the first measurement when the data has loaded.
  const measurementDefinitionsData = useMeasurementDefinitions();
  const measurement = useMeasurement();
  const selectMeasurement = useSelectMeasurement();
  useEffect(() => {
    if (!measurementDefinitionsData.loading && measurementDefinitionsData.data && !measurement) {
      selectMeasurement(measurementDefinitionsData.data[0].measurementDefinition);
    }
  }, [measurementDefinitionsData, measurement, selectMeasurement]);

  // Reset the selection to the first measurement whenever the patient changes.
  useEffect(() => {
    if (patient && measurementDefinitionsData.data) {
      selectMeasurement(measurementDefinitionsData.data[0].measurementDefinition);
    }
  }, [patient, measurementDefinitionsData, selectMeasurement]);

  const [expansionState, setExpansionState] = useState<ExpansionState>(ExpansionState.Both);

  return (
    <Stack
      direction='row'
      spacing={expansionState !== ExpansionState.OnlyPatientList ? 2 : 0}
      alignItems='stretch'
      width='100%'
      height='100%'
    >
      <Stack
        flex={
          expansionState === ExpansionState.OnlyPatientFrame
            ? 0
            : expansionState === ExpansionState.OnlyPatientList
            ? 12
            : expansionState === ExpansionState.Both
            ? 4
            : assertExhaustive(expansionState)
        }
        flexDirection='column'
      >
        <LeftNavigation
          expansionState={expansionState}
          setExpansionState={setExpansionState}
          selectNextPatientRef={selectNextPatientRef}
          selectPrevPatientRef={selectPrevPatientRef}
        />
      </Stack>

      <Stack
        flex={
          expansionState === ExpansionState.OnlyPatientFrame
            ? 12
            : expansionState === ExpansionState.OnlyPatientList
            ? 0
            : expansionState === ExpansionState.Both
            ? 8
            : assertExhaustive(expansionState)
        }
        flexDirection='column'
        overflow={expansionState === ExpansionState.OnlyPatientList ? 'hidden' : 'visible'}
        visibility={expansionState === ExpansionState.OnlyPatientList ? 'hidden' : 'visible'}
      >
        <PatientFrame onDoneReviewing={onDoneReviewing} />
      </Stack>
    </Stack>
  );
};

export default PatientApprover;
