import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import useAuth from 'auth/UseAuth';
import { LoadingStatus } from 'components/LoadingStatus';
import { PatientCountType } from 'components/grid/GridCountType';
import { ChangeEvent, useEffect, useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { LlCustomerOption, ResearchProjectOption } from 'researchProjectManagement/ResearchProjectAutocompleteOptions';
import { CreateResearchProjectModal } from 'researchProjectManagement/CreateResearchProjectModal';
import { CreateCustomerModal } from 'researchProjectManagement/CreateCustomerModal';
import { LlCustomer } from 'data/LlCustomerData';
import { ItemOfResearch, ResearchProjectDetails } from 'data/ResearchProjectData';
import { appSettings } from 'AppSettings';
import {
  CreateResearchProjectPatientsDto,
  getSamplesToInclude,
  ResearchProjectPatientCreateResults,
} from 'data/ResearchProjectPatientData';
import { PatientSelectionStatus } from 'data/ResearchProjectCohortData';
import { DialogCloseButton } from '../components/DialogCloseButton';
import { useCustomerOptions } from 'components/hooks/UseCustomerOptions';
import { useResearchProjectOptions } from 'components/hooks/UseResearchProjectOptions';
import { ValidatePatientCountParameters } from './PatientRecordValidation';
import { PrimaryButton } from 'components/PrimaryButton';
import { CancelButton } from 'components/CancelButton';
import { SamplesToIncludeCheckBoxes } from 'researchProjectManagement/SamplesToIncludeCheckboxes';
import { TimeInterval } from 'data/PatientRecordOptions';
import { useDiseaseAreaOptions } from '../components/hooks/UseDiseaseAreaOptions';
import { useSuperDuperFiestaPipelineOptions } from '../components/hooks/UseSuperDuperFiestaPipelineOptions';

interface AssignPatientsToResearchProjectModalProps {
  itemOfResearch: ItemOfResearch;
  timeInterval: TimeInterval;
  labId?: string;
  biobankId?: string;
  sampleTypeId?: string;
  numRecords: number;
  countType: PatientCountType;
  disabled: boolean;
}

export const AssignPatientsToResearchProjectModal = ({
  itemOfResearch,
  timeInterval,
  biobankId,
  labId,
  sampleTypeId,
  numRecords,
  countType,
  disabled,
}: AssignPatientsToResearchProjectModalProps) => {
  ValidatePatientCountParameters(countType, biobankId, labId, sampleTypeId);

  const { accessToken } = useAuth();
  const theme = useTheme();
  const defaultPatientSelectionStatus = 'possible';

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(disabled);
  const [status, setStatus] = useState<LoadingStatus>('NotStarted');
  const [statusMessage, setStatusMessage] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<LlCustomerOption | null>(null);
  const [selectedResearchProject, setSelectedResearchProject] = useState<ResearchProjectOption | null>(null);
  const [patientSelectionStatus, setPatientSelectionStatus] =
    useState<PatientSelectionStatus>(defaultPatientSelectionStatus);
  const [filteredResearchProjectOptions, setFilteredResearchProjectOptions] = useState<ResearchProjectOption[]>([]);

  const [customerOptions, customersAreRefreshed, reloadCustomerOptions] = useCustomerOptions();
  const [diseaseAreaOptions] = useDiseaseAreaOptions();
  const [pipelineOptions] = useSuperDuperFiestaPipelineOptions();
  const [researchProjectOptions, researchProjectsAreRefreshed, reloadResearchProjectOptions] =
    useResearchProjectOptions();

  const [includeBiobankedSamples, setIncludeBiobankedSamples] = useState<boolean>(false);
  const [includeNonBiobankedSamples, setIncludeNonBiobankedSamples] = useState<boolean>(false);

  useEffect(() => {
    if (itemOfResearch.type === 'DiseaseArea') {
      setIncludeBiobankedSamples(true);
      setIncludeNonBiobankedSamples(false);
    } else {
      setIncludeBiobankedSamples(false);
      setIncludeNonBiobankedSamples(true);
    }
  }, [itemOfResearch.type]);

  const handleClose = () => {
    clearSelectedState();
    setOpen(false);
  };

  const handleAssign = async () => {
    setStatus('Loading');
    try {
      if (selectedResearchProject !== null && accessToken) {
        const researchProject = selectedResearchProject.researchProject;

        const createDto: CreateResearchProjectPatientsDto = {
          researchProjectId: researchProject.researchProjectId,
          itemOfResearchId: itemOfResearch.id,
          itemOfResearchType: itemOfResearch.type,
          timeInterval: timeInterval,
          patientSelectionStatus: patientSelectionStatus,
          samplesToInclude: getSamplesToInclude(includeBiobankedSamples, includeNonBiobankedSamples),
          biobankId: countType === 'byBiobank' ? biobankId : undefined,
          labId: countType === 'byLab' ? labId : undefined,
          sampleTypeId: countType === 'bySampleType' ? sampleTypeId : undefined,
        };

        const response = await fetch(`${appSettings.api.endpoint}/api/v2/ResearchProjectCohort/Patients`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(createDto),
        });

        if (response.ok) {
          const results: ResearchProjectPatientCreateResults = await response.json();
          setStatusMessage(
            `Of ${numRecords} patients in list:\n    -  ${results.numberInsertedPatients} new patients added to project\n    -  ${results.numberConflictingPatients} patients are already included in project`
          );
          setStatus('Complete');
        } else {
          const responseBody: { message: string } = await response.json();
          setStatus('Error');

          if (response.status === 400 && responseBody.message) {
            setStatusMessage(responseBody.message);
          } else if (response.status === 401) {
            setStatusMessage('Error: unauthorized user.');
          } else {
            setStatusMessage('Unknown error.');
          }
        }
      }
    } catch (err) {
      setStatus('Error');
      setStatusMessage('Unknown error.');
    }
  };

  const clearSelectedState = () => {
    setButtonDisabled(disabled);
    setStatusMessage(undefined);
    setSelectedCustomer(null);
    setSelectedResearchProject(null);
    setFilteredResearchProjectOptions([]);
    setPatientSelectionStatus(defaultPatientSelectionStatus);
  };

  const handleLlCustomerChange = (llCustomer?: LlCustomerOption | null) => {
    setSelectedResearchProject(null);
    setSelectedCustomer(llCustomer ?? null);
    setFilteredResearchProjectOptions(
      researchProjectOptions.filter(r => r.researchProject.customer.llCustomerId === llCustomer?.customer.llCustomerId)
    );
  };

  async function handleCreateLlCustomer(newCustomer: LlCustomer) {
    let newCustomerOption: LlCustomerOption = { label: newCustomer.name, customer: newCustomer };
    setSelectedCustomer(newCustomerOption);
    reloadCustomerOptions(!customersAreRefreshed);
  }

  const handleResearchProjectChange = (researchProject?: ResearchProjectOption) => {
    setSelectedResearchProject(researchProject ?? null);
  };

  async function handleCreateResearchProject(newResearchProject: ResearchProjectDetails) {
    let newResearchProjectOption: ResearchProjectOption = {
      label: newResearchProject.name,
      researchProject: newResearchProject,
    };
    setSelectedResearchProject(newResearchProjectOption);
    reloadResearchProjectOptions(!researchProjectsAreRefreshed);
  }

  const handlePatientSelectionStatusChange = async (ev: ChangeEvent<HTMLInputElement>) => {
    ev.persist();
    setPatientSelectionStatus(ev.target.value as PatientSelectionStatus);
  };

  return (
    <>
      <Button
        disabled={buttonDisabled}
        onClick={() => setOpen(true)}
        sx={{
          color: theme.colors.alpha.black[100],
          ml: 3,
        }}
      >
        {status === 'NotStarted' && <PlaylistAddIcon />}
        {status === 'Loading' && <HourglassBottomIcon />}
        {status === 'Complete' && <PlaylistAddCheckIcon />}
        {status === 'Error' && <ErrorOutlineIcon />}
        <Typography variant='button' ml={1}>
          Assign to Research Project
        </Typography>
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='xs'>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          <Typography variant='h4'>Assign to Research Project</Typography>
        </DialogTitle>
        <DialogContent>
          <Box component='form' sx={{ marginTop: 2 }}>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Autocomplete
                id='assign-patients-to-project-customer-input'
                fullWidth
                value={selectedCustomer}
                options={customerOptions}
                renderInput={params => <TextField {...params} label='Customer *' margin='normal' />}
                onChange={(event, value) => handleLlCustomerChange(value ?? undefined)}
              />
              <CreateCustomerModal onCustomerCreate={handleCreateLlCustomer} />
            </Box>
            <Box display='flex' flexDirection='row' alignItems='center'>
              <Autocomplete
                id='assign-patients-to-project-research-project-input'
                fullWidth
                disabled={selectedCustomer === null}
                value={selectedResearchProject}
                options={filteredResearchProjectOptions}
                renderInput={params => <TextField {...params} label='Research Project *' margin='normal' />}
                onChange={(event, value) => handleResearchProjectChange(value ?? undefined)}
              />
              <CreateResearchProjectModal
                initialCustomerOption={selectedCustomer}
                customerOptions={customerOptions}
                diseaseAreaOptions={diseaseAreaOptions}
                pipelineOptions={pipelineOptions}
                onCustomerCreate={handleCreateLlCustomer}
                onResearchProjectCreate={handleCreateResearchProject}
                onInitialCustomerChange={handleLlCustomerChange}
              />
            </Box>
            <SamplesToIncludeCheckBoxes
              includeBiobankedSamples={includeBiobankedSamples}
              includeNonBiobankedSamples={includeNonBiobankedSamples}
              onIncludeBiobankedSamplesChange={() => {
                setIncludeBiobankedSamples(!includeBiobankedSamples);
              }}
              onIncludeNonBiobankedSamplesChange={() => {
                setIncludeNonBiobankedSamples(!includeNonBiobankedSamples);
              }}
            />
            <Box mt={2.5}>
              <FormLabel sx={{ mb: 1 }}>Patient Selection Status</FormLabel>
              <RadioGroup>
                <FormControlLabel
                  value='assign-patients-to-project-possible-radio-button'
                  label='Possible'
                  control={
                    <Radio
                      size='small'
                      checked={patientSelectionStatus === 'possible'}
                      value='possible'
                      onChange={handlePatientSelectionStatusChange}
                    />
                  }
                />
                <FormControlLabel
                  value='assign-patients-to-project-selected-radio-button'
                  label='Selected'
                  control={
                    <Radio
                      size='small'
                      checked={patientSelectionStatus === 'selected'}
                      value='selected'
                      onChange={handlePatientSelectionStatusChange}
                    />
                  }
                />
                <FormControlLabel
                  value='assign-patients-to-project-selected-experimental-radio-button'
                  label='Selected - Experimental'
                  control={
                    <Radio
                      size='small'
                      checked={patientSelectionStatus === 'selectedExperimental'}
                      value='selectedExperimental'
                      onChange={handlePatientSelectionStatusChange}
                    />
                  }
                />
                <FormControlLabel
                  value='assign-patients-to-project-selected-control-radio-button'
                  label='Selected - Control'
                  control={
                    <Radio
                      size='small'
                      checked={patientSelectionStatus === 'selectedControl'}
                      value='selectedControl'
                      onChange={handlePatientSelectionStatusChange}
                    />
                  }
                />
              </RadioGroup>
            </Box>
          </Box>
          {status === 'Complete' && (
            <DialogContentText display='flex' alignItems='center' mt={1} whiteSpace='pre'>
              {statusMessage}
            </DialogContentText>
          )}
          {status === 'Error' && statusMessage !== undefined && (
            <DialogContentText display='flex' alignItems='center' mt={1} color='error'>
              <ErrorOutlineIcon sx={{ mr: 1 }} />
              {statusMessage}
            </DialogContentText>
          )}
        </DialogContent>
        {status === 'Loading' && <LinearProgress />}
        <DialogActions>
          <CancelButton onClick={handleClose} disabled={status === 'Loading'}>
            Close
          </CancelButton>
          <PrimaryButton
            onClick={handleAssign}
            disabled={
              selectedCustomer === null ||
              selectedResearchProject === null ||
              status === 'Loading' ||
              (!includeBiobankedSamples && !includeNonBiobankedSamples)
            }
          >
            Assign Patients
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
